<template>
    <v-container fluid class="fill-height text-left pa-0">
        <v-card class="fill-height container--fluid" width="100vw" flat tile>
            <v-row justify="start">
                <v-col cols="12">
                    <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                        <v-scroll-x-transition appear>
                            <v-img
                                    src="@/assets/images/bg-breadcrumbs.jpg"
                                    transition="slide-x-transition"
                                    position="center"
                                    width="100%"
                                    height="200"
                                    max-height="200">
                                <v-container
                                        fluid
                                        class="fill-height align-end">
                                    <v-row align="end" justify="start">
                                        <v-col cols="12">
                                            <div class="white--text">
                                                <span class="white--text pl-5 text-h4 text-md-h3 font-weight-black">
                                                    Manténte informado...
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-breadcrumbs dark :items="itemsBreadcrumbs">
                                                <template v-slot:divider>
                                                    <v-icon>mdi-arrow-right</v-icon>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-breadcrumbs-item
                                                            :href="item.href"
                                                            :disabled="item.disabled">
                                                        <span class="white--text">
                                                            {{ item.text }}
                                                        </span>
                                                    </v-breadcrumbs-item>
                                                </template>
                                            </v-breadcrumbs>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-img>
                        </v-scroll-x-transition>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-row justify="center">
                        <span class="mt-5 text-h5 text-md-h4 font-weight-ligh headline text-uppercase">
                            Sección de Noticias
                        </span>
                    </v-row>
                    <v-row justify="center">
                        <div class="boxdivider"></div>
                    </v-row>
                </v-col>
                <v-col cols="12" class="align-center justify-center">
                    <v-card class="fill-height container--fluid mx-1 mb-5 pt-0" width="100vw" flat tile>
                        <v-container fluid class="fill-height">
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-container>
                                        <v-row>
                                            <v-col  v-for="(post, i) in blogs"
                                                    :key="i"
                                                    cols="12">
                                                <v-card outlined>
                                                    <!--class="d-flex flex-nowrap justify-space-between"-->
                                                    <div>
                                                        <v-card-title
                                                            class="text-h5 pb-0"
                                                            v-text="post.title">
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-icon small dense color="grey lighten-1">
                                                                mdi-account
                                                            </v-icon>
                                                            <span class="text-caption">
                                                                {{ post.author | trimText }}
                                                            </span>
                                                            <v-icon small dense color="grey lighten-1">
                                                                mdi-calendar-blank
                                                            </v-icon>
                                                            <span class="text-caption">
                                                                {{ post.created_at | formatDate }}
                                                            </span>
                                                        </v-card-text>
                                                        <v-container fluid class="mt-0 pt-0">
                                                            <v-row dense class="ma-0">
                                                                <v-col cols="12" md="4" class="pt-0">
                                                                    <v-card flat class="fill-height pt-0">
                                                                        <v-img  :src="post.pictures[0] != null ? post.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                                :height="$vuetify.breakpoint.xs ? 250: 200"
                                                                                class="white--text align-center justify-center elevation-0">
                                                                        </v-img>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col cols="12" md="8" class="pt-0">
                                                                    <div class="fill-height pl-2 pt-3 pt-md-0 text-body-1 truncate-overflow">
                                                                        {{ post.content }}
                                                                    </div>
                                                                    <!--<v-card flat class="fill-height pt-3 pt-md-0" height="120">
                                                                        <v-card-text class="pl-2 pt-3 pt-md-0 parragraph-truncate" v-html="post.content"></v-card-text>-->
                                                                       <!-- <p class="parragraph-truncate pt-0 pl-2">
                                                                            {{ post.content }}
                                                                        </p>-->
                                                                    <!--</v-card>-->
                                                                    <div class="v-card__actions pl-2 pt-3">
                                                                        <v-btn rounded
                                                                               color="primary"
                                                                               outlined
                                                                               small
                                                                               @click="showPost(post.id)">
                                                                            <span>Seguir leyendo...</span>
                                                                        </v-btn>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters class="mt-0 pt-0">
                                                                <v-col cols="12">
                                                                    <div v-if="post.categories" >
                                                                        <v-chip small v-for="(cat, i) in post.categories" :key="i" class="mr-1">
                                                                            {{ cat.name }}
                                                                        </v-chip>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-container class="max-width" v-show="false">
                                                <v-pagination
                                                        v-model="page"
                                                        class="my-0"
                                                        :length="blogs.length"
                                                        :total-visible="7"
                                                ></v-pagination>
                                            </v-container>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Buscar Blog</span>
                                                    </v-card-title>
                                                    <div class="mt-1">
                                                        <v-text-field
                                                                clearable
                                                                dense
                                                                flat
                                                                hide-details
                                                                id="findtext-blog"
                                                                label="Buscar blog..."
                                                                name="find"
                                                                outlined
                                                                placeholder="Buscar blog..."
                                                                prepend-inner-icon="mdi-magnify"
                                                                single-line>
                                                        </v-text-field>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Posts Recientes</span>
                                                    </v-card-title>
                                                    <div class="mt-1">
                                                        <v-list outlined flat>
                                                            <v-list-item-group
                                                                    color="primary">
                                                                <template v-for="(item, index) in recentPosts">
                                                                    <v-list-item :key="index">
                                                                        <v-list-item-icon class="mr-0">
                                                                             <v-icon>
                                                                                 mdi-newspaper
                                                                             </v-icon>
                                                                         </v-list-item-icon>
                                                                        <v-list-item-action @click="showPost(item.id)">
                                                                            <v-list-item-title v-html="item.title" @onclick="showPost(item.index)"></v-list-item-title>
                                                                        </v-list-item-action>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col col="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Etiquetas</span>
                                                    </v-card-title>
                                                    <div class="mt-2">
                                                        <v-chip v-for="(cat, i) in categories" :key="i" class="mr-1 mt-1">
                                                            {{ cat.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" v-show="false">
                                                <v-form ref="form" v-model="valid" lazy-validation>
                                                    <v-card flat rounded="false" class="mr-2">
                                                        <v-card-title class="pa-1 blue darken-1">
                                                            <span class="text-subtitle-1 white--text">Suscribirse</span>
                                                        </v-card-title>
                                                        <div class="mt-5">
                                                            <v-text-field
                                                                    dense
                                                                    v-model="suscriptionItem.email"
                                                                    :rules="emailRules"
                                                                    label="Correo electrónico"
                                                                    required>>
                                                            </v-text-field>
                                                        </div>
                                                        <v-card-actions class="px-0">
                                                            <v-btn :disabled="!valid" block color="info" @click="submitSuscription">
                                                                Enviar
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
                v-model="snackBar"
                bottom
                :timeout="3000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import blogsService from "@/providers/BlogsService";
    import blogsCatService from '@/providers/BlogsCategoriesService';
    import suscriptionService from '@/providers/SuscriptionService';
    import moment from 'moment';
    
    export default {
        name: "BlogsComponent",
        data: () => ({
            page: 1,
            selectedId: 0,
            valid: false,
            snackBar: false,
            snackText: '',
            searchBlog: '',
            categories: [],
            blogs: [],
            recentPosts: [],
            suscriptionItem : {
                email: ''
            },
            defaultItem : {
                email: ''
            },
            itemsBreadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/homepage',
                },
                {
                    text: 'Blogs y Post',
                    disabled: true,
                    href: '/blogs',
                }
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ]
        }),
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).locale('es').format('MMM D, YYYY [a las] hh:mm a');
                }
            },
            initials(value) {
                const name = value.split(' ');
                return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
            },
            trimText(value) {
                const name = value.trim();
                return name;
            }
        },
        methods: {
            getBlogs() {
                this.loadingData = true;
                // Nomenclador de Categorias
                blogsCatService.getAllRecords().then(record => {
                    this.categories = record.value;
                    // console.log("Categorías: ", this.categories);
                });
                // Carga la lista de Posts Recientes
                blogsService.getViewListRecents().then(record => {
                    this.recentPosts = record.value;
                    // console.log("Blogs Recientes: ", this.recentPosts);
                });
                // Registro de Blogs...
                blogsService.getViewListPosts().then(record => {
                    this.blogs = record.value;
                    // console.log("Blogs: ", this.blogs);
                    this.loadingData = false;
                });
            },
            submitSuscription() {
                // console.log('Codigo para enviar la suscripcion!!!');
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // Agrega el registro por el metodo POST
                    suscriptionService.addRecord(this.suscriptionItem).then(result => {
                        if (result.success) {
                            this.suscriptionItem = Object.assign({}, this.defaultItem);
                            this.snackText = 'Su solicitud de suscripción ha sido enviada...';
                        } else {
                            this.snackText = 'Un error impidió enviar la solicitud!';
                        }
                        this.snackBar = true;
                        this.$refs.form.reset();
                    });
                }
            },
            showPost(idBlog) {
                this.selectedId = idBlog;
                this.$router.push({
                    name: "BlogPost",
                    params: {
                        id: idBlog,
                    }
                });
            }
        },
        mounted() {
            this.blogs = [];
            this.categories = [];
            this.recentPosts = [];
            this.suscriptionItem = Object.assign({}, this.defaultItem);
            this.getBlogs();
        },
    }
</script>

<style scoped>
    .bottom-gradient {
        background-image: linear-gradient(
                to bottom,
                rgba(163, 153, 248, 0.295) 0%,
                rgba(101, 99, 248, 0.699) 100%
        );
    }
    .blend-opacity {
        opacity: 0.1;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }

    .parragraph-truncate {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        max-width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-line;
        /*word-wrap: break-word;*/
        height:155px;
    }

    .truncate-overflow {
        --lh: 1.4rem;
        line-height: var(--lh);
        --max-lines: 7;
        position: relative;
        max-height: calc(var(--lh) * var(--max-lines));
        overflow: hidden;
        padding-right: 1rem; /* space for ellipsis */
        text-overflow: ellipsis;
    }
    .truncate-overflow::before {
        position: absolute;
        content: "...";
        inset-block-end: 0; /* "bottom" */
        inset-inline-end: 0; /* "right" */
        text-overflow: ellipsis;
    }
    .truncate-overflow::after {
        content: "";
        position: absolute;
        inset-inline-end: 0; /* "right" */
        width: 1rem;
        height: 1rem;
        background: white;
        text-overflow: ellipsis;
    }

</style>